import {
  IContentItemWriterFragment,
  IContentWriterFragment,
  IEnum_Componentcontentcontentwriter_Writeroption,
} from 'src/graphql/generated/hooks'

import { getFullName } from '../utils/getFullName'
import { getSolutionUrl } from '../utils/getSolutionUrl'
import { useDefaultLogo } from './useDefaultLogo'

interface ContentWriterInformation {
  name: string
  title: string | undefined | null
  company: string | undefined | null
  originalAvatar: string | undefined | null
  logo: string
  description: string | undefined | null
  option: IEnum_Componentcontentcontentwriter_Writeroption
  url: string | undefined | null
}

const isContentWriterFragment = (
  writer: IContentWriterFragment | IContentItemWriterFragment | string,
): writer is IContentWriterFragment => typeof writer !== 'string' && 'expert' in writer

export const useContentWriterInformation = (
  writer: IContentWriterFragment | IContentItemWriterFragment | string | null | undefined,
): ContentWriterInformation | null => {
  let originalAvatar: string | undefined | null = null
  let option: IEnum_Componentcontentcontentwriter_Writeroption | null = null
  let url: string | null = null
  let name: string | undefined | null = null
  let title: string | undefined | null = null
  let company: string | undefined | null = null
  let description: string | undefined | null = null

  if (!writer) {
    name = null
  } else if (typeof writer === 'string') {
    name = writer
    option = IEnum_Componentcontentcontentwriter_Writeroption.OneTimeAuthor
  } else if (isContentWriterFragment(writer)) {
    if (writer.writerOption === IEnum_Componentcontentcontentwriter_Writeroption.Expert) {
      name = writer.expert?.data?.attributes?.displayName
      title = writer.expert?.data?.attributes?.title
      company = writer.expert?.data?.attributes?.company
      originalAvatar = writer.expert?.data?.attributes?.avatar?.data?.attributes?.url
      option = IEnum_Componentcontentcontentwriter_Writeroption.Expert
      const slug = writer.expert?.data?.attributes?.slug
      url = slug ? `/experts/${slug}` : null
      description = writer.expert?.data?.attributes?.bio
    } else if (writer.writerOption === IEnum_Componentcontentcontentwriter_Writeroption.Author) {
      name = getFullName([writer.author?.data?.attributes?.firstName, writer.author?.data?.attributes?.lastName])
      title = writer.author?.data?.attributes?.jobTitle
      company = writer.author?.data?.attributes?.company
      originalAvatar = writer.author?.data?.attributes?.avatar?.data?.attributes?.url
      option = IEnum_Componentcontentcontentwriter_Writeroption.Author
      const slug = writer.author?.data?.attributes?.slug
      url = slug ? `/authors/${slug}` : null
      description = writer.author?.data?.attributes?.bio
    } else if (writer.writerOption === IEnum_Componentcontentcontentwriter_Writeroption.Vendor) {
      name = getFullName([writer.author?.data?.attributes?.firstName, writer.author?.data?.attributes?.lastName])
      originalAvatar = writer.solution?.data?.attributes?.logo?.data?.attributes?.url
      option = IEnum_Componentcontentcontentwriter_Writeroption.Vendor
      if (writer.solution?.data) {
        url = getSolutionUrl(writer.solution?.data)
      }
      description = writer.solution?.data?.attributes?.shortDescription
    } else {
      name = writer.oneTimeAuthorName
      option = IEnum_Componentcontentcontentwriter_Writeroption.OneTimeAuthor
    }
  } else {
    name = writer.name
    originalAvatar = writer.avatar
    option = writer.writerOption
    url = writer.url || null
  }

  const logo = useDefaultLogo(originalAvatar)
  if (!writer || !name || !option) {
    return null
  }
  return {
    name,
    title,
    company,
    logo,
    url,
    originalAvatar,
    description,
    option,
  }
}
