import Head from 'next/head'
import { NextSeo } from 'next-seo'
import { MetaTag, OpenGraphArticle } from 'next-seo/lib/types'
import { SITE_URL, TWITTER_USER_TITLE } from 'src/config/config'
import { removeTags } from 'src/utils/removeTags'

import { AppConfig } from '../utils/AppConfig'

type IMetaProps = {
  title: string
  description: string
  url: string
  image?: string
  keywords?: string[]
  type?: 'website' | 'article'
  article?: OpenGraphArticle
  additionalMetaTags?: MetaTag[]
}

const Meta: React.FC<IMetaProps> = ({
  title,
  description,
  url,
  image = null,
  keywords = [],
  type = 'website',
  article = {},
  additionalMetaTags = [],
}) => {
  const defaultLogo = 'https://media.legaltechnologyhub.com/LTH_Premium_Homepage_c404c230a9.png'
  const fullUrl = url.startsWith('/') ? `${SITE_URL}${url}` : url
  const finalUrl = fullUrl.endsWith('/') ? fullUrl : `${fullUrl}/`
  const keywordsContent = [
    'LegalTech',
    'LegalTechnology',
    'legal',
    'legaltechhub',
    'legaltechnologyhub',
    ...keywords.filter((content) => !!content).map((content) => removeTags(content)),
  ].join(',')
  const metaTags = additionalMetaTags.concat([{ name: 'keywords', content: keywordsContent }])

  if (article) {
    if (article.authors) {
      metaTags.push({ name: 'twitter:label1', content: 'Written By' })
      metaTags.push({ name: 'twitter:data1', content: article.authors.join(',') })
      metaTags.push({ name: 'twitter:label2', content: 'Created at' })
      metaTags.push({ name: 'twitter:data2', content: article.publishedTime || '' })
    }
  }

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta name="viewport" content="width=device-width,initial-scale=1" key="viewport" />
        <link rel="icon" type="image/png" sizes="32x32" href={`${SITE_URL}/favicon-32x32.png`} key="icon32" />
        <link rel="icon" type="image/png" sizes="16x16" href={`${SITE_URL}/favicon-16x16.png`} key="icon16" />
        <link rel="icon" href={`${SITE_URL}/favicon.ico`} key="favicon" />
      </Head>
      <NextSeo
        title={title}
        titleTemplate="%s | Legaltech Hub"
        defaultTitle="Legaltech Hub"
        description={removeTags(description)}
        canonical={finalUrl}
        additionalMetaTags={metaTags}
        openGraph={{
          url: finalUrl,
          title,
          description: removeTags(description),
          locale: AppConfig.locale,
          site_name: AppConfig.site_name,
          images: [{ url: image || defaultLogo, alt: title }],
          type: type,
          article,
        }}
        twitter={{
          cardType: 'summary_large_image',
          handle: TWITTER_USER_TITLE,
          site: TWITTER_USER_TITLE,
        }}
      />
    </>
  )
}

export { Meta }
