import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import LogoLightSVG from '../../assets/light_bg_logo.svg'

export const IltaFooter: React.FC = () => {
  return (
    <div className="bg-clouds min-h-[80px] w-full flex items-center">
      <div className="max-w-[1100px] m-auto flex flex-row items-center h-full justify-between">
        <div className="font-primary font-medium leading-[16px] text-[14px] text-lthblue tracking-[1.5px] mr-5">
          SPONSOR DIRECTORY POWERED BY
        </div>
        <Link href="https://www.legaltechnologyhub.com/" target="_blank">
          <Image src={LogoLightSVG} alt="" />
        </Link>
      </div>
    </div>
  )
}
