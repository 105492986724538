import { IEnum_Solution_Type } from 'src/graphql/generated/hooks'

type Params = {
  attributes?: {
    slug?: string | null
    type?: IEnum_Solution_Type | null
  } | null
}

export const getSolutionUrl = (params: Params): string => {
  if (!params.attributes?.type || !params.attributes?.slug) {
    return '/'
  }

  const solutionType = params.attributes.type
  const slug = params.attributes.slug
  if (solutionType === IEnum_Solution_Type.Alsp) {
    return `/alsps/${slug}/`
  } else if (solutionType === IEnum_Solution_Type.Consultancy) {
    return `/consultants/${slug}/`
  } else if (solutionType === IEnum_Solution_Type.Product) {
    return `/vendors/${slug}/`
  }
  return '/'
}
