import cn from 'classnames'
import Link from 'next/link'
import React, { memo } from 'react'
import { LinkButton } from 'src/components/global/LinkButton'
import { ISubTopicItemFragment, ITopicItemFragment } from 'src/graphql/generated/hooks'
import { useAuthenticated } from 'src/hooks/useAuthenticated'

interface Props {
  className?: string
  premiumCategories: (ITopicItemFragment | ISubTopicItemFragment)[]
}

interface Item {
  url: string
  name: string
}

const isTopicItemFragment = (category: ITopicItemFragment | ISubTopicItemFragment): category is ITopicItemFragment =>
  !!category.attributes && 'subTopics' in category.attributes

export const PremiumSubTopics: React.FC<Props> = memo(({ className, premiumCategories }) => {
  const { session } = useAuthenticated()
  const isPremium = session?.user?.isPremium || false

  const categories = premiumCategories
    .map((category) => {
      if (isTopicItemFragment(category)) {
        return {
          url: '/topics/' + category.attributes?.slug,
          name: category.attributes?.name,
        }
      }
      if (category.attributes?.topics?.data.length === 0) {
        return null
      }
      return {
        url: '/topics/' + category.attributes?.topics?.data[0].attributes?.slug + '/' + category.attributes?.slug,
        name: category.attributes?.name,
      }
    })
    .filter((item): item is Item => !!item)

  return (
    <div className={cn('grid md:grid-flow-col grid-rows-6 gap-1 mb-5 w-full', className)}>
      {categories.map((item, idx) => (
        <Link key={idx} href={isPremium ? item.url : `${item.url}?tab=directory`}>
          <LinkButton type="icontopic" label={item.name || ''} className="w-full" key={idx} />
        </Link>
      ))}
    </div>
  )
})
