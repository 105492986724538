import { Hidden } from '@mui/material'
import Image from 'next/image'
import {
  IContentItemWriterFragment,
  IContentWriterFragment,
  IEnum_Componentcontentcontentwriter_Writeroption,
} from 'src/graphql/generated/hooks'
import { useContentWriterInformation } from 'src/hooks/useContentWriterInformation'

import { CompatibleLink } from '../CompatibleLink/CompatibleLink'

interface ContentWriterProps {
  writer: IContentWriterFragment | IContentItemWriterFragment | string
}

export const ContentWriter: React.FC<ContentWriterProps> = ({ writer }) => {
  const info = useContentWriterInformation(writer)
  if (!info) {
    return null
  }
  const { name, option, logo, url } = info

  const divContent = (
    <>
      {option !== IEnum_Componentcontentcontentwriter_Writeroption.Vendor && (
        <Image src={logo} alt="" className="w-[26px] h-[26px] rounded-[13px]" width={26} height={26} priority />
      )}
      {option === IEnum_Componentcontentcontentwriter_Writeroption.Vendor && (
        <div className="rounded-[3px] bg-clouds p-[2px]">
          <Image src={logo} alt="" className="w-[26px] h-[26px] rounded-[13px]" priority width={26} height={26} />
        </div>
      )}
      <Hidden mdDown>
        <p className="font-primary font-bold text-[15px] leading-[18px] mr-[0.5rem] ml-[5px] text-darkgray">{name}</p>
      </Hidden>
    </>
  )
  return url ? (
    <CompatibleLink href={url} className="flex flex-row items-center">
      {divContent}
    </CompatibleLink>
  ) : (
    <div className="flex flex-row items-center">{divContent}</div>
  )
}
