import cn from 'classnames'
import React from 'react'

interface LinkButtonProps {
  type?: 'primary' | 'secondary' | 'highlighted' | 'icontopic'
  label: string
  icon?: string
  disabled?: boolean
  onClick?: () => void
  className?: string
}

/**
 * Primary UI component for user interaction
 */
export const LinkButton: React.FC<LinkButtonProps> = ({
  type = 'primary',
  label,
  icon,
  disabled = false,
  className,
  onClick,
  ...props
}) => {
  return (
    <div className={cn(`${type}`, `${className}`)}>
      <button type="button" className={cn(' w-full h-full')} onClick={onClick} disabled={disabled} {...props}>
        {
          <div className="ml-3 flex items-center">
            <div className="bg-navybright w-[5px] h-[5px]" style={{ borderRadius: '5px' }}></div>
            <span className="ml-3 text-navybright font-semibold">{label}</span>
          </div>
        }
      </button>
    </div>
  )
}
