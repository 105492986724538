import dynamic from 'next/dynamic'

export const Footer = dynamic(() => import('./FooterDynamic').then((mod) => mod.FooterDynamic), {
  loading: () => <p>Loading...</p>,
})

/* export const Footer = dynamic(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(import('./FooterDynamic').then((mod) => mod.FooterDynamic))
      }, 20000) // Adjust the delay as needed (2000ms = 2 seconds)
    }),
  {
    loading: () => <p>Loading...</p>, // Loading state
  },
) */
